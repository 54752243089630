.RegisterForm-title {
  color: antiquewhite;
}
.RegisterForm-card {
  background-color: antiquewhite;
}

.instructions {
  font-size: 0.6rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  border-radius: 0.25rem;
  padding: 0.5rem;
  margin: 0 auto 0.5rem;
  width: 900px;
}

.line {
  display: inline-block;
}
