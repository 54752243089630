.Navbar-navbar {
  /* background-color: beige; */

  background-image: linear-gradient(
    0deg,
    hsl(11deg 52% 4%) 0%,
    hsl(42deg 40% 30%) 42%,
    hsl(43deg 40% 42%) 65%,
    hsl(43deg 41% 51%) 81%,
    hsl(43deg 56% 59%) 92%,
    hsl(43deg 75% 65%) 100%
  );
  max-height: 6vh;
}

.navbar-brand {
  color: antiquewhite;
}

.navbar-brand img {
  border-radius: 50%;
}

.nav-link {
  color: antiquewhite !important;
}
