.Missing-card {
  margin: 2rem auto;
  width: 50vw;
  font-size: 1.5rem;
  align-items: center;
  background: linear-gradient(
    to top,
    rgb(238, 220, 192) 0%,
    rgb(222, 201, 167) 62%
  );
}

.Missing-link {
  color: rgb(14, 14, 220);
}
