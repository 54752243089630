/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  color: #fff;
}

body {
  overflow-y: hidden;
  min-height: 100vh;
  /* background-color: dodgerblue; */
  background-image: url("./images/brewery-print-shoppe-background-keg-beer.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgba(77, 82, 86, 0.5);
  background-blend-mode: lighten;
}

.card {
  background-color: antiquewhite;
}

.hidden {
  display: none;
}

a {
  text-decoration: none;
  color: white;
}
