.UserBreweries-title {
  color: antiquewhite;
}

.UserBreweries-container {
  margin: 1em auto;
  padding: 0;
  max-width: 900px;
  background-color: antiquewhite;
  border-radius: 10px;
}

#table-container {
  overflow-y: scroll;
  max-height: 80vh;
}

.badge {
  margin-right: 10px;
  border: 0;
}

.UserBreweries-list-group-item {
  background-color: rgba(244, 230, 175, 0.698);
  border: 1px solid black;
  margin-top: 2px;
}

.UserBreweries-name:hover {
  cursor: pointer;
}
